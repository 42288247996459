import React from 'react';
import _ from '../../lodash-wrapper';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames/bind';

import Button from '@material-ui/core/Button';
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Link } from 'react-router-dom';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import { getScoreDisplayText, convertRatingDistributionToPercentage } from '../../utils';

const StyledRating = withStyles(theme => ({
    iconFilled: {
        color: theme.palette.primary.main,
    },
}))(Rating);

const useStyles = makeStyles(theme => ({
    containerPadding: {
        paddingRight: '20px'
    },
    cardRoot: {
        width: '90%',
        maxWidth: '270px',
    },
    cardContent: {
        paddingRight: 0,
    },
    ratingAverage: {
        color: theme.palette.primary.main,
        fontWeight: 400,
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('md')]: {fontSize: '30px'},
        [theme.breakpoints.down('sm')]: {fontSize: '24px'},
    },
    ratingNaString: {
        [theme.breakpoints.up('md')]: {fontSize: '30px'},
        [theme.breakpoints.down('sm')]: {fontSize: '18px'},
    },
    ratingCount: {
        color: '#707070',
        fontSize: '13px',
    },
    scoreText: {
        color: '#666666',
        fontSize: '10px',
    },
    countText: {
        color: '#666666',
        fontSize: '10px',
        marginLeft: '5px',
    },
    ratingDistributionArea: {
        marginTop: '10px',
    },
    ratingItemRow: {
        marginBottom: '5px'
    },
    ratingBar: {
        borderRadius: '5px',
        height: '10px',
        backgroundColor: '#EEEEEE'
    },
    knowMoreDestination: {
        marginTop: '20px'
    }
}));

function RatingItemRow(props) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {score, percentage} = props;
    if(score === 0)
        return null;
    return (
        <Grid className={classes.ratingItemRow} container direction='row' alignItems='center'>
            <Grid item xs={2}><Typography className={classes.scoreText}>{getScoreDisplayText(score)}</Typography></Grid>
            <Grid item xs={8}><LinearProgress className={classes.ratingBar} variant="determinate" value={percentage} /></Grid>
            <Grid item xs={2}><Typography className={classes.countText}>{_.round(percentage, 0)}%</Typography></Grid>
        </Grid>
    ); 
}
RatingItemRow.propTypes = {
    score: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
};

export function RatingAverageValue(props) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { ratingAverage, naString } = props;
    const ratingAverageDisplayed = ratingAverage? ratingAverage.toFixed(1): naString;
    return (
        <Typography className={classNames(classes.ratingAverage, !ratingAverage && classes.ratingNaString)}>
            {ratingAverageDisplayed}
        </Typography>
    );
 }
RatingAverageValue.propTypes = {
    ratingAverage: PropTypes.number,
    naString: PropTypes.string
 }
 RatingAverageValue.defaultProps = {
     naString: ' - '
 }

function RatingSummarySection(props) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {ratingAverage, total} = props;
    return (
        <Grid container direction='row' alignItems='stretch'>
            <Grid item xs={3}>
                <RatingAverageValue ratingAverage={ratingAverage} />
            </Grid>
            <Grid item xs={9}>
                <StyledRating value={ratingAverage} precision={0.01} readOnly/>
                <Typography className={classes.ratingCount}>
                    {total? `${total}人已評分` : '暫時未有評分'}
                </Typography>
            </Grid>
        </Grid>
    );
}
RatingSummarySection.propTypes = {
    total: PropTypes.number.isRequired,
    ratingAverage: PropTypes.number,
}

function RatingDistributionCard(props) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {className, ratingDistribution, ratingAverage, knowMoreDestination, knowMoreDestionationButtonText} = props;
    const {scoreArr, total} = convertRatingDistributionToPercentage(ratingDistribution);

    return (
        <div className={classNames(className, knowMoreDestination && classes.containerPadding)}>
            <Card className={classes.cardRoot}>
                <CardContent className={classes.cardContent}>
                    <Grid container direction='column'>
                        <RatingSummarySection ratingAverage={ratingAverage} total={total}/>
                        <Grid container className={classes.ratingDistributionArea} direction='column-reverse' alignItems='stretch'>
                            {
                                scoreArr.map((percentage, score) => (
                                    <RatingItemRow key={score} score={score} percentage={percentage} />
                                ))
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {knowMoreDestination &&
            <Grid container className={classes.knowMoreDestination} direction='row-reverse'>
                <Grid item>
                    <Button
                        component={Link}
                        variant="contained" 
                        color="primary"
                        to={knowMoreDestination}
                    >
                        {knowMoreDestionationButtonText}
                    </Button>
                </Grid>
            </Grid>
            }
        </div>
    );
}

RatingDistributionCard.propTypes = {
    className: PropTypes.string,
    ratingDistribution: PropTypes.array,
    ratingAverage: PropTypes.number,
    knowMoreDestination: PropTypes.string,
    knowMoreDestionationButtonText: PropTypes.string,
}
RatingDistributionCard.defaultProps = {
    knowMoreDestination: null,
    knowMoreDestionationButtonText: '',
}

export default RatingDistributionCard;