import axios from 'axios';
import FormData from 'form-data';
import queryString from 'query-string';
import { getBackendBaseUrl } from '../utils';
import {
    parseAuthError,
    parseCourseRegistrationError,
    parseGenericError,
    parseCoursePublishError,
    parseCourseRatingError,
    parseUpdateCourseLogisticsError,
    parseSubmitStudentStatusApplicationError,
} from '../models/error';
import {
    CurrentUser,
    LoginResponseSchema,
    AuthOKResponse,
    CurrentUserSchema,
    UserPreference,
    UserPreferenceSchema,
    AvatarUpdateResponse,
    AvatarUpdateResponseSchema,
    UserRatingList,
    UserRatingListSchema,
} from '../models/user';
import {
    CourseDetailsSchema,
    CourseDetails,
} from '../models/course';
import {
    CourseGroupDetailsSchema,
    CourseGroupDetails,
    CourseGroupSearchResultSchema,
    CourseGroupSearchResult,
} from '../models/courseGroup';
import {
    CourseRatingInfo,
    CourseRatingInfoSchema,
} from '../models/courseRating';
import {
    CourseGroupRatingInfo,
    CourseGroupRatingInfoSchema,
    CourseGroupRatingList,
    CourseGroupRatingListSchema,
} from '../models/courseGroupRating';
import {
    Rating,
    RatingSchema,
} from '../models/rating';
import {
    InitiateCourseRegistrationResponseSchema,
    CourseRegistrationConfirmResponseSchema,
    InitiateCourseRegistrationResponse,
    CourseRegistrationConfirmResponse,
} from '../models/courseRegistration';
import {
    UserProfile,
    UserProfileSchema,
} from '../models/userProfile';
import {
    CourseAnnouncement,
    CourseAnnouncementSchema,
    CourseLogistics,
    CourseLogisticsResponse,
    CourseLogisticsResponseSchema,
    CourseLogisticsSchema
} from '../models/courseLogistics';
import {
    CourseStatusManagementView,
    CourseStatusManagementViewSchema,
} from '../models/courseStatusResponse';
import {
    CourseMessage,
    CourseMessageBoardInfo,
    CourseMessageBoardInfoSchema,
    CourseMessageSchema,
} from '../models/courseMessageBoard';
import {
    CourseMessageBoardPreference,
    CourseMessageBoardPreferenceSchema,
} from '../models/courseMessageBoardPreference';
import {
    BLOG_LISTING_SEARCH_DEFAULT_PARAMS,
    BLOG_LISTING_PAGE_SIZE,
    GROUP_SEARCH_DEFAULT_PARAMS,
    GROUP_SEARCH_PAGE_SIZE
} from '../constants';
import {
    CourseGroupOrdering
} from '../models/enums';
import {
    StudentStatusApplication,
    StudentStatusApplicationSchema,
} from '../models/studentStatusApplication';
import {
    StripeUserPortal,
    StripeUserPortalSchema,
} from '../models/stripeUserPortal';
import {
    CourseRecordingResponse,
    CourseRecordingResponseSchema,
} from '../models/courseRecording';
import {
    FaqListing,
    FaqListSchema
} from '../models/faq';
import {AllCoupon, AllCouponSchema} from "../models/coupon";
import {
    BlogDetail,
    BlogDetailSchema,
    BlogListingResult,
    BlogListingResultSchema,
    BlogTagList,
    BlogTagListSchema,
} from "../models/blog";
import {
    CourseRecordingsAdminListResponse,
    CourseRecordingsAdminListResponseSchema,
    CourseRecordingAdmin,
    CourseRecordingAdminSchema,
} from '../models/courseRecordingAdmin';
import {
    S3DirectUploadParamsResponse,
    S3DirectUploadParamsResponseSchema,
    S3DirectSignatureResponse,
    S3DirectSignatureResponseSchema,
} from '../models/s3Direct';

const prepareAttribute = (name, value) => {
    if(value === null)
        return {};
    return { [name]: value };
}

export class APIClient {
    getTransport = (token = null, headers={}, timeout=5000) => {
        let headersCombined = {...headers};
        if(token) {
            headersCombined.Authorization = `Token ${token}`;
        }
        return axios.create({
            baseURL: getBackendBaseUrl(),
            timeout,
            headers: headersCombined,
            paramsSerializer: queryString.stringify
        });
    }

    async login(email, password) {
        try {
            const res = LoginResponseSchema.validateSync(
                (await this
                    .getTransport()
                    .post(
                        '/rest-auth/login/',
                        {email, password}
                    )
                ).data
            );
            return {
                key: res.key,
                user: new CurrentUser(res.user),
            };
        }
        catch(e) {
            throw parseAuthError(e);
        }
    }
    async register(
        displayName,
        password1,
        password2,
        email,
        first_name,
        last_name,
        date_of_birth, // YYYY-MM-DD
        recaptcha
    ) {
        try{
            AuthOKResponse.validateSync(
                (await this
                    .getTransport()
                    .post(
                        '/rest-auth/registration/',
                        {
                            display_name: displayName,
                            password1,
                            password2,
                            email,
                            recaptcha,
                            first_name,
                            last_name,
                            date_of_birth,
                        }
                    )
                ).data);
        }
        catch(e) {
            throw parseAuthError(e);
        }
    }

    async checkRegistrationInput(displayName, password, email) {
        try{
            await this.getTransport()
            .post(
                '/rest-auth/registration/',
                {
                    display_name: displayName,
                    email,
                    password1: password
                }
            );
        }
        catch(e) {
            if(e.response) {
                return {
                    password: (e.response.data.password1 && e.response.data.password1[0]) || null,
                    email: (e.response.data.email && e.response.data.email[0]) || null,
                }
            }
            throw parseAuthError(e);
        }
        return {
            password: null,
            email: null,
        };
    }

    async activateUser(key) {
        try{
            await this
                .getTransport()
                .post(
                    '/rest-auth/registration/verify-email/',
                    {
                        key
                    }
                );
            return true;
        }
        catch(e) {
            if(e.response.status === 404)
                return false;
            throw parseAuthError(e);
        }
    }

    async logout(token) {
        try{
            await this
                .getTransport(token)
                .post(
                    '/rest-auth/logout/',
                    {}
                );
        }
        catch(e) {
            // Ignore any error as we still want to destory token in local storage
        }
    }

    async getCurrentUser(token) {
        try{
            return new CurrentUser(CurrentUserSchema.validateSync(
                (await this
                .getTransport(token)
                .get('/rest-auth/user/')).data
            ));
        }
        catch(e) {
            // we will invalidate token if there is any error
        }
        return null;
    }

    async changePassword(token, oldPassword, newPassword) {
        try{
            await this
                .getTransport(token)
                .post(
                    '/rest-auth/password/change/',
                    {
                        "old_password": oldPassword,
                        "new_password1": newPassword,
                        "new_password2": newPassword
                    }
                );
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async resetPassword(recaptcha, email) {
        try{
            await this
                .getTransport()
                .post(
                    '/rest-auth/password/reset/',
                    {
                        recaptcha,
                        email
                    }
                );
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async resetPasswordConfirm(uid, key, newPassword) {
        try{
            await this
                .getTransport()
                .post(
                    '/rest-auth/password/reset/confirm/',
                    {
                        uid,
                        token: key,
                        new_password1: newPassword,
                        new_password2: newPassword,
                    }
                );
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async getCourseDetails(token, courseId) {
        try{
            return new CourseDetails(CourseDetailsSchema
            .validateSync(
                (await this
                .getTransport(token)
                .get('course/'+courseId)).data
            ));
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async initiateCourseRegistration(token, courseId) {
        try{
            return new InitiateCourseRegistrationResponse(
                InitiateCourseRegistrationResponseSchema
                .validateSync(
                    (await this
                    .getTransport(token)
                    .post('/course/'+courseId+'/register')).data
                )
            );
        }
        catch(e) {
            throw parseCourseRegistrationError(e);
        }
    }

    async confirmCourseRegistration(token, courseId) {
        try{
            return new CourseRegistrationConfirmResponse(CourseRegistrationConfirmResponseSchema
            .validateSync(
                (await this
                .getTransport(token, {}, 10000)
                .post(
                    '/course/'+courseId+'/register-confirm',
                    {
                    }
                )).data
            ));
        }
        catch(e) {
            throw parseCourseRegistrationError(e);
        }
    }


    async retrieveCourseGroupDetails(token, groupId) {
        try{
            return new CourseGroupDetails(CourseGroupDetailsSchema
            .validateSync(
                (await this
                .getTransport(token)
                .get('/course-group/'+groupId)).data
            ));
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async retrieveUserProfile(token, userId) {
        try{
            return new UserProfile(UserProfileSchema
                .validateSync(
                    (await this.getTransport(token).get(`/user/${userId}/profile`)).data
            ))
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async retrieveCourseLogistics(token, courseId) {
        try {
            return new CourseLogisticsResponse(CourseLogisticsResponseSchema
                .validateSync(
                    (await this.getTransport(token).get(`course/${courseId}/logistics`)).data
            ))
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async createCourseAnnouncement(
        token,
        courseId,
        message,
        sendsNotification
    ) {
        try {
            return new CourseAnnouncement(CourseAnnouncementSchema
                .validateSync(
                    (await this.getTransport(token).post(
                                `course/${courseId}/logistics/announcement`,
                                {message, sends_notification: sendsNotification}
                    )).data
            ))
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async updateCourseLogistics(
        token,
        courseId,
        meetingUrl,
        courseMaterialsUrl,
        meetingDetails,
    ) {
        try {
            return new CourseLogistics(CourseLogisticsSchema
                .validateSync(
                    (await this.getTransport(token).patch(
                                `course/${courseId}/logistics/update`,
                                {
                                    ...(meetingUrl? {meeting_url: meetingUrl}: {}),
                                    ...(courseMaterialsUrl? {course_materials_url: courseMaterialsUrl}: {}),
                                    ...(meetingDetails? {meeting_details: meetingDetails}: {})
                                }
                    )).data
            ))
        }
        catch(e) {
            throw parseUpdateCourseLogisticsError(e);
        }
    }

    async getCourseStatusOverview(
        token,
        courseId
    ) {
        try {
            return new CourseStatusManagementView(CourseStatusManagementViewSchema
                .validateSync(
                    (await this.getTransport(token).get(`course/${courseId}/status`)).data
            ))
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async publishCourse(
        token,
        courseId
    ) {
        try {
            await this.getTransport(token).post(`course/${courseId}/publish`);
        }
        catch(e) {
            throw parseCoursePublishError(e);
        }
    }

    async updateUserDetails(
        token,
        displayName,
        dateOfBirth,
        educationDescription,
        firstName,
        gender,
        lastName,
        selfIntroduction,
        interestedCategories,
    ) {
        try {
            return new CurrentUser(CurrentUserSchema
                .validateSync(
                    (await this.getTransport(token).patch(
                        'rest-auth/user/',
                        {
                            display_name: displayName,
                            date_of_birth: dateOfBirth,
                            education_description: educationDescription,
                            first_name: firstName,
                            gender,
                            last_name: lastName,
                            self_introduction: selfIntroduction,
                            interested_categories: interestedCategories,
                        }
                    )).data
            ));
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async submitFirstLoginQuestionnaire(
        token,
        educationDescription,
        interestedCategories
    ) {
        try {
            return new CurrentUser(CurrentUserSchema
                .validateSync(
                    (await this.getTransport(token).patch(
                        'rest-auth/user/',
                        {
                            education_description: educationDescription,
                            interested_categories: interestedCategories,
                        }
                    )).data
            ));
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async validatePassword(
        password
    ) {
        try {
            await this.getTransport().post('password-reset/validate', { password });
            return [];
        }
        catch(e) {
            if(e.response && e.response.status === 400 && e.response.data.password)
                return e.response.data.password;
            throw parseGenericError(e);
        }
    }

    async retrieveUserPreference(token) {
        try {
            return new UserPreference(UserPreferenceSchema
                .validateSync(
                    (await this.getTransport(token).get('user-preference')).data
            ));
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async updateUserPreference(token, publicProfile, publicCoursesTaken) {
        try {
            return new UserPreference(UserPreferenceSchema
                .validateSync(
                    (await this.getTransport(token).put(
                        'user-preference',
                        {
                            public_profile: publicProfile,
                            public_courses_taken: publicCoursesTaken
                        }
                    )).data
            ));
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async updateAvatar(token, imageFile) {
        let form = new FormData();
        form.append('avatar', imageFile);
        try {
            return new AvatarUpdateResponse(AvatarUpdateResponseSchema
                .validateSync(
                    (await this.getTransport(token).put('user-avatar', form)).data
            ));
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async retrieveCourseRatingInfo(token, courseId) {
        try {
            return new CourseRatingInfo(CourseRatingInfoSchema
                .validateSync(
                    (await this.getTransport(token).get(`course/${courseId}/ratings`)).data
            ));
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async updateCourseRating(token, courseId, score, comment) {
        try {
            return new Rating(RatingSchema
                .validateSync(
                    (await this.getTransport(token)
                        .post(
                            `course/${courseId}/ratings/update`,
                            {
                                score,
                                comment
                            }
                        )
                    ).data
            ));
        }
        catch(e) {
            throw parseCourseRatingError(e);
        }
    }

    async retrieveCourseGroupRatingInfo(groupId) {
        try {
            return new CourseGroupRatingInfo(CourseGroupRatingInfoSchema
                .validateSync(
                    (await this.getTransport().get(`course-group/${groupId}/ratings`)).data
            ));
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async listCourseGroupRatings(groupId, page) {
        try {
            return new CourseGroupRatingList(CourseGroupRatingListSchema
                .validateSync(
                    (await this.getTransport().get(`course-group/${groupId}/ratings/list?page=${page}`)).data
            ));
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async searchCourseGroup(
        params=GROUP_SEARCH_DEFAULT_PARAMS,
        page=1,
        ordering=CourseGroupOrdering.LATEST
    ) {
        try {
            return new CourseGroupSearchResult(
                CourseGroupSearchResultSchema
                .validateSync((
                    await this.getTransport().get(
                        '/course-group',
                        {
                            params: {
                                ...prepareAttribute('query', params.query),
                                ...prepareAttribute('mediums', params.medium, true),
                                ...prepareAttribute('languages', params.language, true),
                                ...prepareAttribute('categories', params.category, true),
                                ...prepareAttribute('course_levels', params.courseLevel, true),
                                ...prepareAttribute('price_amount_min', params.priceAmountMin),
                                ...prepareAttribute('price_amount_max', params.priceAmountMax),
                                ...prepareAttribute('total_duration_minute_min', params.totalDurationMinuteMin),
                                ...prepareAttribute('total_duration_minute_max', params.totalDurationMinuteMax),
                                ...prepareAttribute('rating_average_min', params.ratingAverageMin),
                                ...prepareAttribute('rating_average_max', params.ratingAverageMax),
                                ...prepareAttribute('page', page),
                                page_size: GROUP_SEARCH_PAGE_SIZE,
                                order_by: ordering.sortingId,
                            }
                        }
                    )
                ).data)
            );
        }
        catch(e) {
            throw parseGenericError(e);
        }
    }

    async validateCourseAdminAccess(
        token = '',
        courseId
    ) {
        try {
            const resp = await this.getTransport(token).get(`/course/${courseId}/admin-access`);

            return resp.status === 200;
        } catch (e) {
            throw parseGenericError(e);
        }
    }

    async retrieveStudentStatusApplication(
        token = '',
    ) {
        try {
            return new StudentStatusApplication(
                StudentStatusApplicationSchema.validateSync(
                    (
                        await this.getTransport(token).get(`student-status-application`)
                    ).data
                )
            )
        } catch (e) {
            throw parseGenericError(e);
        }
    }

    async submitStudentStatusApplication(
        token = '',
        studentCardImageFile,
        selfieImageFile
    ) {
        let form = new FormData();
        form.append('student_card_image', studentCardImageFile);
        form.append('selfie_image', selfieImageFile);
        try {
            const res = await this.getTransport(token).post('student-status-application/create', form);
            return res.status === 201;
        } catch (e) {
            throw parseSubmitStudentStatusApplicationError(e);
        }
    }

    async exportCourseEnrolledStudentsCSV(
        token = '',
        courseId
    ) {
        try {
            return await this.getTransport(token).get(`/course/${courseId}/enrolled-students`)
        } catch (e) {
            throw parseGenericError(e);
        }
    }

    async retrieveCourseMessageBoardInfo(
        token = '',
        courseId
    ) {
        try {
            return new CourseMessageBoardInfo(CourseMessageBoardInfoSchema
                .validateSync(
                    (await this.getTransport(token).get(`course/${courseId}/message-board`)).data
                ));
        }
        catch (e) {
            throw parseGenericError(e);
        }
    }

    async createCourseMessage(
        token = '',
        courseId,
        content,
        quoteId
    ) {
        try {
            return new CourseMessage(CourseMessageSchema.validateSync(
                (await this.getTransport(token).post(`course/${courseId}/message-board/message`, {
                    content,
                    quoteId
                })).data
            ));
        } catch (e) {
            throw parseGenericError(e);
        }
    }

    async retrieveCourseMessageBoardPreference(
        token = '',
        courseId,
    ) {
        try {
            return new CourseMessageBoardPreference(CourseMessageBoardPreferenceSchema
                .validateSync(
                    (await this.getTransport(token).get(`course/${courseId}/message-board/preference`)).data
                )
            );
        } catch (e) {
            throw parseGenericError(e);
        }
    }

    async updateCourseMessageBoardPreference(
        token = '',
        courseId,
        emailNotification
    ) {
        try {
            return new CourseMessageBoardPreference(CourseMessageBoardPreferenceSchema
                .validateSync(
                    (await this.getTransport(token).put(`course/${courseId}/message-board/preference`, {
                        email_notification: emailNotification,
                    })).data
                )
            );
        } catch (e) {
            throw parseGenericError(e);
        }
    }

    async getStripeUserPortalUrl(
        token,
    ) {
        try {
            return new StripeUserPortal(
                StripeUserPortalSchema
                .validateSync((
                    await this.getTransport(token)
                    .get(`stripe-user-profile`))
                    .data
                ));
        } catch (e) {
            throw parseGenericError(e);
        }    
    }
        
    async retrieveCourseRecordings(
        token = '',
        courseId,
    ) {
        try {
            return new CourseRecordingResponse(
                CourseRecordingResponseSchema
                .validateSync((await this.getTransport(token)
                .get(`course/${courseId}/recordings`)).data)
                );
        } catch (e) {
            throw parseGenericError(e);
        }
    }

    async retrieveFaqs(slug) {
        try {
            const response = await this.getTransport().get(`/faq/${slug}`);
            return new FaqListing(FaqListSchema.validateSync(response.data));


        } catch (e) {
            throw parseGenericError(e);
        }
    }

    async retrieveAllCoupons(
        token = '',
    ) {
        try {
            return new AllCoupon(AllCouponSchema
                .validateSync(
                    (await this.getTransport(token).get('coupon')).data
                )
            );
        } catch (e) {
            throw parseGenericError(e);
        }

    }

    async listUserRating(userId, page=1) {
        try {
            return new UserRatingList(UserRatingListSchema
                .validateSync(
                    (await this.getTransport().get(`user/${userId}/ratings/list?page=${page}`)).data
                )
            );
        } catch (e) {
            throw parseGenericError(e);
        }
    }

    async listBlogs(
        params = BLOG_LISTING_SEARCH_DEFAULT_PARAMS,
        page=1
    ) {
        try {
            const response =  await this.getTransport().get('/blog', {
                params: {
                    ...prepareAttribute('tag', params.tag),
                    ...prepareAttribute('author', params.author),
                    ...prepareAttribute('page', page),
                    page_size: BLOG_LISTING_PAGE_SIZE,
                }
            });
            return new BlogListingResult(BlogListingResultSchema.validateSync(response.data));
        } catch(e) {
            throw parseGenericError(e);
        }
    }

    async retrieveBlog(slug) {
        try {
            const response = await this.getTransport().get(`/blog/${slug}`);
            return new BlogDetail(BlogDetailSchema.validateSync(response.data));
        } catch (e) {
            throw parseGenericError(e);
        }
    }

    async retrieveAdminCourseRecordings(token = '', courseId) {
        try {
            return new CourseRecordingsAdminListResponse(CourseRecordingsAdminListResponseSchema
                .validateSync(
                    (await this.getTransport(token).get(`course/${courseId}/recordings/admin`)).data
                )
            );
        } catch (e) {
            throw parseGenericError(e);
        }
    }

    async initAdminCourseRecordingUpload(
        token = '',
        courseId,
        sessionId,
        fileName,
        fileType,
        fileSize,
    ) {
        try {
            return new S3DirectUploadParamsResponse(S3DirectUploadParamsResponseSchema
                .validateSync(
                    (await this
                        .getTransport(token)
                        .post(
                            `course/${courseId}/recordings/admin/${sessionId}/upload/init`,
                            {
                                file_name: fileName,
                                file_type: fileType,
                                file_size: fileSize,
                            }
                        )
                    ).data
                )
            );
        } catch (e) {
            throw parseGenericError(e);
        }
    }

    async signAdminCourseRecordingUploadPart(
        token = '',
        courseId,
        sessionId,
        message,
        signingDate,
        canonicalRequest,
    ) {
        try {
            return new S3DirectSignatureResponse(S3DirectSignatureResponseSchema
                .validateSync(
                    (await this
                        .getTransport(token)
                        .post(
                            `course/${courseId}/recordings/admin/${sessionId}/upload/sign`,
                            {
                                message,
                                signing_date: signingDate,
                                canonical_request: canonicalRequest,
                            }
                        )
                    ).data
                )
            );
        } catch (e) {
            throw parseGenericError(e);
        }
    }

    async completeAdminCourseRecordingUpload(
        token = '',
        courseId,
        sessionId,
        path,
    ) {
        try {
            return new CourseRecordingAdmin(CourseRecordingAdminSchema
                .validateSync(
                    (await this
                        .getTransport(token, {}, 30000)
                        .post(
                            `course/${courseId}/recordings/admin/${sessionId}/upload/complete`,
                            {
                                path,
                            }
                        )
                    ).data
                )
            );
        } catch (e) {
            throw parseGenericError(e);
        }
    }

    async editCourseRecording(
        token = '',
        courseId,
        recordingId,
        hiddenFromStudents,
    ) {
        try {
            return new CourseRecordingAdmin(CourseRecordingAdminSchema
                .validateSync(
                    (await this
                        .getTransport(token)
                        .patch(
                            `course/${courseId}/recording/${recordingId}`,
                            {
                                'hidden_from_students': hiddenFromStudents,
                            }
                        )
                    ).data
                )
            );
        } catch (e) {
            throw parseGenericError(e);
        }
    }

    async retrieveBlogTagList() {
        try {
            return new BlogTagList(BlogTagListSchema
                .validateSync((await this.getTransport().get('/blog-tag')).data)
            );
        } catch (e) {
            throw parseGenericError(e);
        }
    }
}
