import _ from './lodash-wrapper';

const makeMarks = (marks, makeLabel=v=>`${v}`) => marks.map(v => {
    return {value: v, label: makeLabel(v)};
})

export const PRICE_AMOUNT_MIN = 0;
export const PRICE_AMOUNT_MAX = 3000;
export const PRICE_AMOUNT_MARKS = makeMarks([PRICE_AMOUNT_MIN, 1000, 2000, PRICE_AMOUNT_MAX], p => `$${p}`);

export const RATING_MIN = 1;
export const RATING_MAX = 5;
export const RATING_MARKS = makeMarks(_.range(RATING_MIN, RATING_MAX+1));

export const GROUP_SEARCH_DEFAULT_PARAMS = {
    query: null,
    medium: null,
    language: null,
    category: null,
    courseLevel: null,
    priceAmountMin: null,
    priceAmountMax: null,
    totalDurationMinuteMin: null,
    totalDurationMinuteMax: null,
    ratingAverageMin: null,
    ratingAverageMax: null,
};

export const GROUP_SEARCH_PAGE_SIZE = 12;
export const BANNER_IMAGE_WIDTH_TO_HEIGHT_RATIO = 1.7778;
export const COURSE_COVER_WIDTH_TO_HEIGHT_RATIO = 1.7778;
export const HOME_SCREEN_INITIAL_SEARCH_OPTION_ID = 'RECOMMENDED';

// Please refer to the document of date-fns when formatting the date.
// https://date-fns.org/v2.19.0/docs/format

export const DATE_DISPLAY_FORMAT = 'y-MM-dd';
export const DAY_OF_WEEK_DISPLAY_FORMAT = '(EEEEE)';
export const TIME_DISPLAY_FORMAT = "HH:mm' (HKT)'";
export const DATETIME_DISPLAY_FORMAT = `${DATE_DISPLAY_FORMAT} ${DAY_OF_WEEK_DISPLAY_FORMAT} ${TIME_DISPLAY_FORMAT}`;
export const DATETIME_DISPLAY_MULTILINE_FORMAT = `${DATE_DISPLAY_FORMAT} ${DAY_OF_WEEK_DISPLAY_FORMAT}'\n'${TIME_DISPLAY_FORMAT}`;
export const SHORT_DATETIME_DISPLAY_FORMAT = "M'月'd'日, 'HH':'mm";

export const INSTAGRAM_LINK = 'https://www.instagram.com/hok6dotcom';
export const FB_LINK = 'https://www.facebook.com/hok6dotcom';
export const YOUTUBE_LINK = 'https://www.youtube.com/c/學識Hok6';
export const KOFI_LINK = 'https://ko-fi.com/hok6dotcom';
export const SUPPORT_EMAIL = 'support@hok6.com';

export const ENABLE_PRIVACY_SETTING = false;

export const IMAGE_COMPRESSION_MAX_SIZE_MB = 5.0;
export const IMAGE_UPLOAD_SIZE_SOFT_LIMIT_MB = 10.0;
export const IMAGE_UPLOAD_ALLOWED_EXTENSIONS = [
    'image/png',
    'image/jpeg',
];

export const BLOG_LISTING_SEARCH_DEFAULT_PARAMS = {
    tag: null,
    author: null,
};

export const BLOG_LISTING_PAGE_SIZE = 10;

export const COURSE_RECORDING_ALLOWED_EXTENSIONS = [
    'video/mp4',
    'video/webm',
    'video/quicktime',
    'video/h263',
    'video/h264',
    'video/ogg',
]

export const COURSE_RECORDING_UPLOAD_SIZE_LIMIT_GB = 10;
