import React, { useRef } from 'react';
import _ from '../../lodash-wrapper';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Box, Card, CardActionArea, CardContent, Button, Avatar } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import VideoCamIcon from "@material-ui/icons/Videocam";
import TranslateIcon from "@material-ui/icons/Translate";
import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { CourseReduced } from '../../models/course';
import { CourseGroupReduced,  } from '../../models/courseGroup';
import { CourseTaken, CourseTaught } from '../../models/userProfile';
import { CourseMedium, CourseLanguage } from '../../models/enums';
import { numberMap, datetimeFormat } from '../../utils';
//import { numberMap, useContainerDimensions, datetimeFormat } from '../../utils';
import { COURSE_COVER_WIDTH_TO_HEIGHT_RATIO, SHORT_DATETIME_DISPLAY_FORMAT } from '../../constants';
import { PublicUser } from '../../models/user';

const useStyles = makeStyles((theme) => ({
    cardRoot:{
        minHeight: '315px',
    },
    minCardRoot:{
        margin: '10px',
        [theme.breakpoints.up(460)]: {margin: '20px'},
        [theme.breakpoints.up(600)]: {margin: '5px'},
        [theme.breakpoints.up(800)]: {margin: '10px'},
        [theme.breakpoints.up(960)]: {margin: '10px'},
        [theme.breakpoints.up(1000)]: {margin: '20px'},
    },
    avatar: {
        textAlign: 'right',
        padding: 0,
        margin: 'auto',
        width: 60,
        height: 60,
    },
    smallAvatar: {
        width: 45,
        height: 45,
        marginLeft: '4px' 
    },
    courseTitle: {
        fontWeight: 600,
        fontSize: '15px',
        textAlign: 'left',
        width: '100%',
    },
    courseTeacherName: {
        fontSize: '14px',
        textAlign: 'left',
        minHeight: '50px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%',
        whiteSpace: 'nowrap',
        color: 'gray',
        marginTop: 5
    },
    courseHead: {
        height: '65px',
    },
    courseBody: {
        height: '110px',
        [theme.breakpoints.up('md')]: {
            height: '110px',
        }
    },
    coursePriceGrid: {
        display: 'flex',
        paddingTop: 10,
        textAlign: 'center',        
    },
    coursePriceWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    discountRemainingSeatsText: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20px',
        color: '#FFF',
        boxShadow: 'none',
        backgroundColor: '#FC8268',
        borderRadius: '5px',
        fontSize: '12px',
        marginBottom: '5px',
    },
    remainingSeatsText: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20px',
        color: '#FC8268',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        border: '1.5px solid',
        borderColor: '#FC8268',
        borderRadius: '5px',
        fontSize: '12px',
        marginBottom: '5px',
    },
    coursePriceButtonBase: {
        width: '100%',
        height: '48px',
        maxWidth: '100%',
        color: '#FFF',
        boxShadow: 'none',
    },
    courseStatusText: {
        width:'110px',
        textAlign: 'center',
        minHeight: '30px',
        fontSize: '20px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        marginBottom: '2px',
    },
    coursePriceButtonBasePriceAmtShortText: {
        [theme.breakpoints.up('xs')]: {fontSize: 'clamp(12px,3.5cqw,16px)',},
        [theme.breakpoints.up('sm')]: {fontSize: 'clamp(12px,1.6cqw,16px)',},
        [theme.breakpoints.up('md')]: {fontSize: 'clamp(12px,1.2cqw,14px)',},
        [theme.breakpoints.up('lg')]: {fontSize: '14px',},
        lineHeight: 1.2,
        whiteSpace: 'nowrap',
        overflow: 'visible',
        textDecoration: 'line-through',
    },
    coursePriceButtonBasePriceAmtLongText: {
        [theme.breakpoints.up('xs')]: {fontSize: 'clamp(11px,3.4cqw,15px)',},
        [theme.breakpoints.up('sm')]: {fontSize: 'clamp(11px,1.5cqw,15px)',},
        [theme.breakpoints.up('md')]: {fontSize: 'clamp(11px,1.1cqw,13px)',},
        [theme.breakpoints.up('lg')]: {fontSize: '13px',},
        lineHeight: 1.2,
        whiteSpace: 'nowrap',
        overflow: 'visible',
        textDecoration: 'line-through',
      },
    coursePriceButtonPriceAmtShortText: {
        [theme.breakpoints.up('xs')]: {fontSize: 'clamp(16px,4.1cqw,21px)',},
        [theme.breakpoints.up('sm')]: {fontSize: 'clamp(14px,1.9cqw,19px)',},
        [theme.breakpoints.up('md')]: {fontSize: 'clamp(14px,1.5cqw,17px)',},
        [theme.breakpoints.up('lg')]: {fontSize: '17px',},
        lineHeight: 1.2,
        whiteSpace: 'nowrap',
        overflow: 'visible'
    },
    coursePriceButtonPriceAmtLongText: {
        [theme.breakpoints.up('xs')]: {fontSize: 'clamp(15px,4cqw,20px)',},
        [theme.breakpoints.up('sm')]: {fontSize: 'clamp(13px,1.8cqw,18px)',},
        [theme.breakpoints.up('md')]: {fontSize: 'clamp(14px,1.4cqw,16px)',},
        [theme.breakpoints.up('lg')]: {fontSize: '16px',},
        lineHeight: 1.2,
        whiteSpace: 'nowrap',
        overflow: 'visible'
      },
    courseSummaryGrid: {
        textAlign: 'left',
    },
    courseSummaryTextBase: {
        fontSize: '15px',
        marginLeft: '-2px',
        maxWidth: '100%',
        whiteSpace: 'nowrap'
    },
    courseSummaryTextEllipsis: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    courseProgressText: {
        fontSize: '14px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        textAlign: 'center',
    },
    courseProgressBar: {
        overflow: 'hidden',
        maxWidth: '100%',
        textAlign: 'center',
    },
    courseSummaryIcon: {
        marginTop: '3px',
        fontSize: '15px',
    },
    mediaImg: {
        display: 'block',
        maxWidth: '100%',
        minHeight: '140px',
        aspectRatio: COURSE_COVER_WIDTH_TO_HEIGHT_RATIO,   
    },
    disabledBoxClass: {
        position: 'absolute',
        display: 'block',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        zIndex: 99,
    },
    avatarBoxClass: {
        display: 'flex',
        justifyContent: 'right',
   },
    avatarWrapper: {
        display: 'flex',
    }
}));


export function CourseCardInternal(props) {
    const { to, courseImage, courseTitle, minimal = false, teachers, subTeacher, datetime, timeslot, medium, language, color, priceAmountText, basePriceAmountText, enrollable, xs, sm, md, lg, isMyProfile, statusText, remainingSeatsToNextDiscountText, remainingSeatsText, ...otherProps } = props;
        
    const classes = useStyles();
    const cardRef = useRef();
    //const { width } = useContainerDimensions(cardRef);
    const courseSummaryText = clsx({
        [classes.courseSummaryTextBase]: true,
        [classes.courseSummaryTextEllipsis]: true,
    })
    const coursePriceButton = clsx({
        [classes.coursePriceButtonBase]: true,

    })
    const coursePriceButtonBasePriceAmt = clsx({
        [classes.coursePriceButtonBasePriceAmtShortText]: priceAmountText.length < 8,
        [classes.coursePriceButtonBasePriceAmtLongText]: priceAmountText.length >= 8,
    })
    const coursePriceButtonPriceAmt = clsx({
        [classes.coursePriceButtonPriceAmtShortText]: priceAmountText.length < 8,
        [classes.coursePriceButtonPriceAmtLongText]: priceAmountText.length >= 8,
    })


    const isMultipleTeacher = teachers && teachers.length > 1;
    const teachersAdaptiveGrid = {
        title: {
            xs: isMultipleTeacher ? 7 : 8,
            sm: isMultipleTeacher ? 8 : 7,
        },
        avatar: {
            xs: isMultipleTeacher ? 5 : 4,
            sm: isMultipleTeacher ? 4 : 5,
        }
    };
    const teachersAvatar = clsx({
        [classes.avatar]: true,
        [classes.smallAvatar]: isMultipleTeacher,
    });

    const renderButtonText = (classes,priceAmountText,basePriceAmountText) => {
        if(basePriceAmountText) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <Typography className={coursePriceButtonBasePriceAmt}>{basePriceAmountText}</Typography>
                    <Typography className={coursePriceButtonPriceAmt }> {priceAmountText}</Typography>
                </div>
            ) 
        } else {
            return (
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <Typography className={coursePriceButtonPriceAmt} > {priceAmountText}</Typography>
                </div>
            )
        }
    }

    // const getProgressBar = (progressPercentageValue) => {

    //     if(progressPercentageValue !== null) {
    //         return (
    //             <progress style={{ maxWidth: '100%'}} value={progressPercentageValue}/>
    //             )
    //     }
    // }

    if (minimal) {
        // Minimal mode: display only the banner
        return (
            <Grid xs={12} sm={6} md={12}>
                <Card className={classes.minCardRoot} elevation={3} {...otherProps}>
                    <CardActionArea component={NavLink} to={to}>
                        <Paper elevation={0}>
                            <img
                                src={courseImage}
                                alt={courseTitle}
                                className={classes.mediaImg}
                                style={{
                                    height: 'auto',
                                    aspectRatio: COURSE_COVER_WIDTH_TO_HEIGHT_RATIO,
                                    objectFit: 'cover',
                                }}
                                loading="lazy"
                            />
                        </Paper>
                    </CardActionArea>
                </Card>
            </Grid>
        );
    }

    return (

            <Grid item xs={xs} sm={sm} md={md} lg={lg}>
               
                <Card ref={cardRef} className={classes.cardRoot} elevation={3} {...otherProps}>
                    <CardActionArea component={NavLink} to={to}>
                        {
                            (!isMyProfile && !enrollable) &&
                            <Box className={classes.disabledBoxClass}></Box>
                        }
                        <Paper elevation={0}>
                            <img
                                src={courseImage}
                                alt={courseTitle}
                                className={classes.mediaImg}
                                style={{ 
                                    width: '100%',
                                    height: 'auto',
                                    aspectRatio: COURSE_COVER_WIDTH_TO_HEIGHT_RATIO,
                                    objectFit: 'cover',
                                    //height: width / COURSE_COVER_WIDTH_TO_HEIGHT_RATIO, zIndex: 10 
                                }}
                                loading="lazy"
                            />
                        </Paper>
                        <CardContent>
                            <Grid container item xs={12} alignItems="flex-start" className={classes.courseHead}>
                                <Grid item {...teachersAdaptiveGrid.title}>
                                    <Typography className={classes.courseTitle}>{courseTitle}</Typography>
                                    {(teachers != null) ? (
                                        <Typography className={classes.courseTeacherName}>
                                            {teachers.map(t => t.displayName).join(" & ")}<br />{subTeacher}
                                        </Typography>) : ''}
                                </Grid>
                                <Grid item className={classes.avatarBoxClass} {...teachersAdaptiveGrid.avatar}>
                                    <Grid item className={classes.avatarWrapper}>
                                    {
                                        teachers.map(teacher => (
                                            <Avatar key={teacher.id} src={teacher.avatar} className={teachersAvatar} />
                                        ))
                                    }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} alignItems="flex-end" className={classes.courseBody}>
                                <Grid container item sm={7} xs={8} className={classes.courseSummaryGrid}>
                                    {isMyProfile &&
                                        <>
                                            <Grid item xs={2} >
                                                <EventIcon color="primary" className={classes.courseSummaryIcon} />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography className={classes.courseSummaryTextBase}>{datetime}</Typography>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={2}>
                                        <QueryBuilderIcon color="primary" className={classes.courseSummaryIcon} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography className={courseSummaryText}>{timeslot}</Typography>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <VideoCamIcon color="primary" className={classes.courseSummaryIcon} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography className={courseSummaryText}>{medium}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TranslateIcon color="secondary" className={classes.courseSummaryIcon} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography className={courseSummaryText}>{language}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item sm={5} xs={4} className={classes.coursePriceGrid}>
                                    <Grid container item xs={12} className={classes.coursePriceWrapper}>
                                        <Typography className={classes.courseStatusText} color="secondary">
                                            {statusText}
                                        </Typography>
                                                                        
                                        {/* <Typography className={classes.courseProgressText} color="secondary">
                                            {progressText}
                                        </Typography>

                                        <Typography className={classes.courseProgressBar} color="secondary">
                                            {getProgressBar(progressPercentageValue)}
                                        </Typography> */}
                                        {/* Remember to define progressText and progressPercentageValue in line 157 if one uncomment this part*/}

                                    {remainingSeatsText && 
                                        <>
                                            <Typography noWrap className={classes.remainingSeatsText}>{remainingSeatsText}</Typography>
                                        </>                               
                                    }
                                    {remainingSeatsToNextDiscountText &&
                                        <>
                                                <Typography noWrap className={classes.discountRemainingSeatsText}>{remainingSeatsToNextDiscountText}</Typography>
                                        </>
                                    }

                                        <Button
                                            variant="contained"
                                            color={color}
                                            className={coursePriceButton}
                                            disabled={!isMyProfile && !enrollable}
                                        >
                                            {renderButtonText(classes,priceAmountText,basePriceAmountText)}

                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>

    );
}

CourseCardInternal.propTypes = {
    to: PropTypes.string,
    courseImage: PropTypes.string,
    courseTitle: PropTypes.string,
    minimal: PropTypes.bool, // Add minimal as optional boolean
    teachers: PropTypes.arrayOf(PropTypes.instanceOf(PublicUser)),
    subTeacher: PropTypes.string,
    datetime: PropTypes.string,
    timeslot: PropTypes.string,
    medium: PropTypes.string,
    language: PropTypes.string,
    color: PropTypes.string,
    priceAmountText: PropTypes.string,
    basePriceAmountText: PropTypes.string,
    enrollable: PropTypes.bool,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    isMyProfile: PropTypes.bool,
    statusText: PropTypes.string,
    progressText: PropTypes.string,
    progressPercentageValue: PropTypes.number,
    remainingSeatsToNextDiscountText: PropTypes.string,
    remainingSeatsText: PropTypes.string,
};

CourseCardInternal.defaultProps = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 4,
    enrollable: true,
    isMyProfile: false,
}

export function CourseCard(props) {
    const { target, urlGenerator, minimal = false, isMyProfile, statusTextMyProfile, ...otherProps } = props;

    let targetAttributes = {}
    if (target && (target instanceof CourseTaught || target instanceof CourseTaken)) {
        targetAttributes = {
            courseSalesMode : target.salesMode,
            courseStatus : target.status,
            courseDiscountApplied : target.discountApplied,
            courseBasePriceAmount : target.basePriceAmount,
            coursePriceAmount : target.priceAmount,
            courseMinimumEnrollment : target.minimumEnrollment,
            courseMedium : target.medium,
            courseCrowdfundCompleted : target.crowdfundCompleted,
            courseRegisteredStudentCount : target.registeredStudentCount,
            courseRemainingSeatsToNextCfThreshold : target.remainingSeatsToNextCfThreshold,
            courseRemainingSeats : target.remainingSeats,
            targetEnrollable : target.enrollable,
            coursePriceCurrency : target.priceCurrency,
            courseIsFree : target.isFree,
        }
    }

    if (target && target instanceof CourseGroupReduced) {
        targetAttributes = {
            courseSalesMode : target.firstCourseSalesMode,
            courseStatus : target.firstCourseStatus,
            courseDiscountApplied : target.firstCourseDiscountApplied,
            courseBasePriceAmount : target.firstCourseBasePriceAmount,
            coursePriceAmount : target.firstCoursePriceAmount,
            courseMinimumEnrollment : target.firstCourseMinimumEnrollment,
            courseMedium : target.firstCourseMedium,
            courseCrowdfundCompleted : target.firstCourseCrowdfundCompleted,
            courseRegisteredStudentCount : target.firstCourseRegisteredStudentCount,
            courseRemainingSeatsToNextCfThreshold : target.firstCourseRemainingSeatsToNextCfThreshold,
            courseRemainingSeats : target.firstCourseRemainingSeats,
            courseEnrollable : target.firstCourseEnrollable,
            targetEnrollable : target.enrollable,
            coursePriceCurrency : target.priceCurrency,
            targetPriceAmountMax : target.priceAmountMax,
        }
    }
    
    const color = (targetAttributes.courseIsFree || targetAttributes.targetPriceAmountMax === '0') ? "primary" : "primary";
    const medium = _.join(
        _.map(
            _.isNil(target.medium) ? target.mediums : [target.medium],
            m => CourseMedium[m].displayName
        ),
        '/'
    );
    const language = _.join(
        _.map(
            _.isNil(target.language) ? target.languages : [target.language],
            l => CourseLanguage[l].displayName
        ),
        '/'
    );

    const getPriceText = targetAttributes => {
        if (!isMyProfile && !targetAttributes.targetEnrollable) return ['','']

        const basePriceAmount = parseInt(targetAttributes.courseBasePriceAmount)
        const priceAmount = parseInt(targetAttributes.coursePriceAmount)

        const basePriceAmountText = (targetAttributes.targetEnrollable && targetAttributes.courseDiscountApplied && targetAttributes.courseBasePriceAmount !== targetAttributes.coursePriceAmount) ? `${targetAttributes.coursePriceCurrency} ${basePriceAmount}` : null;
        const priceAmountText = `${targetAttributes.coursePriceCurrency} ${priceAmount}`;

        if (targetAttributes.courseIsFree || targetAttributes.targetPriceAmountMax === '0')
            return ['免費',null]
        return [priceAmountText,basePriceAmountText];
        
        
        // if (target.isFree || target.priceAmountMax === '0')
        //     return ['免費',null]; 
    
        // if (!_.isNil(target.priceAmount))
        //     return [`${target.priceCurrency} ${target.priceAmount}`,basePriceAmount];
    
        // if (target.priceAmountMin === target.priceAmountMax) {
        //     if (!_.isNil(target.priceAmountMin))
        //         return [`${target.priceCurrency} ${target.priceAmountMin}`,basePriceAmount];
        //     return [` - `,null];
        // }
    
        // return [`${target.priceCurrency} ${target.priceAmountMin} - ${target.priceAmountMax}`,null];
    }
    
    const getProgressText = targetAttributes => {
        const progressPercentageUnrounded = (targetAttributes.courseRegisteredStudentCount / targetAttributes.courseMinimumEnrollment * 100);
        const progressPercentage = Math.round(progressPercentageUnrounded);
        
        if (targetAttributes.courseSalesMode === "CROWDFUNDED"  && targetAttributes.targetEnrollable && !targetAttributes.courseCrowdfundCompleted) {
            return (`進度: ${progressPercentage} %`)
        }
    }
    
    const getProgressPercentageValue = targetAttributes =>{
        const progressPercentageValue = targetAttributes.courseRegisteredStudentCount / targetAttributes.courseMinimumEnrollment
    
        if (targetAttributes.courseSalesMode === "CROWDFUNDED"  && targetAttributes.targetEnrollable && !targetAttributes.courseCrowdfundCompleted) {
            return progressPercentageValue
        } else {
            return null
        }
    }

    const getStatusText = (targetAttributes) => {
        if (!targetAttributes.courseEnrollable) {
            return ''
        }
        if (!targetAttributes.targetEnrollable) {
            return ''
        }
        
        if(targetAttributes.courseSalesMode === 'DIRECTSALES') {
            if(targetAttributes.courseMedium === 'PRERECORDED' || targetAttributes.courseMedium === 'FREE_TRIAL') return '立即上課'
            else return '接受報名' // all other types of course medium
        } 

        if(targetAttributes.courseSalesMode === 'CROWDFUNDED'){
            if(targetAttributes.courseMedium === 'PRERECORDED' || targetAttributes.courseMedium === 'FREE_TRIAL'){ 
                if(targetAttributes.courseStatus === 'PUBLISHED'){
                    return targetAttributes.courseCrowdfundCompleted ? '陸續發佈' : '預售中'
                }else{ // if'CONFIRMED'
                    return "立即上課"
                }
            }
            else{ // non prerecorded type, such as interactive, live streaming or physical activities
                if(targetAttributes.courseStatus === 'PUBLISHED'){
                    return targetAttributes.courseCrowdfundCompleted ? '接受報名' : '預售中'
                }else{ // if'CONFIRMED'
                    return "接受報名"
                }
            }
        }
        
    }


    const getRemainingSeatsToNextDiscountText = (targetAttributes) => {
        const remainingSeats = targetAttributes.courseRemainingSeatsToNextCfThreshold
        const text = `此優惠尚餘 ${remainingSeats} 位`

        if (!targetAttributes.targetEnrollable) return null
        if (!targetAttributes.courseDiscountApplied) return null
        if (targetAttributes.courseSalesMode === 'CROWDFUNDED' && !targetAttributes.courseCrowdfundCompleted) return text
        return '優惠價'
    }

    const getCourseRemainingSeatText = (targetAttributes) => {
        if(!targetAttributes.courseEnrollable) return null
        if(targetAttributes.courseMedium === 'PRERECORDED' || targetAttributes.courseMedium === 'FREE_TRIAL') return null
        if(targetAttributes.courseRemainingSeats === 0)
            return '已滿額'
        if(targetAttributes.courseRemainingSeats < 4)
            return `尚餘${numberMap[targetAttributes.courseRemainingSeats]}個位!`
        if(targetAttributes.courseRemainingSeats < 8)
            return '尚餘少量名額!'
        return null
    }
    
    const [priceAmountText, basePriceAmountText] = getPriceText(targetAttributes);
    const statusTextHomeScreen = getStatusText(targetAttributes) 
    const progressText = getProgressText(targetAttributes)
    const progressPercentageValue = getProgressPercentageValue(targetAttributes)
    const remaingSeatsToNextDiscountText = getRemainingSeatsToNextDiscountText(targetAttributes)
    const courseRemainingSeatText = getCourseRemainingSeatText(targetAttributes)

    return (
    <CourseCardInternal
        key={target.id}
        courseImage={target.coverImage}
        courseTitle={target.title}
        datetime={datetimeFormat(target.nextOrLastSessionTime, SHORT_DATETIME_DISPLAY_FORMAT)}
        timeslot={`${target.totalDurationMinute} 分鐘`}
        teachers={(target.teachers || target.leadingTeachers)}
        medium={medium}
        language={language}
        priceAmountText={priceAmountText}
        basePriceAmountText={basePriceAmountText}
        color={color}
        to={urlGenerator(target)}
        minimal={minimal} // Pass minimal prop
        enrollable={target.enrollable}
        isMyProfile={isMyProfile}
        statusText={
            !isMyProfile ?
                statusTextHomeScreen :
                statusTextMyProfile
        }
        remainingSeatsToNextDiscountText={remaingSeatsToNextDiscountText}
        remainingSeatsText={courseRemainingSeatText}
        progressText={progressText}
        progressPercentageValue={progressPercentageValue}
        {...otherProps}
    />
    )
}
CourseCard.propTypes = {
    target: PropTypes.oneOfType([
        PropTypes.instanceOf(CourseGroupReduced),
        PropTypes.instanceOf(CourseReduced),
        PropTypes.instanceOf(CourseTaken),
        PropTypes.instanceOf(CourseTaught),
    ]).isRequired,
    urlGenerator: PropTypes.func.isRequired,
    minimal: PropTypes.bool, // Add minimal as optional boolean
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    isMyProfile: PropTypes.bool,
    statusTextMyProfile: PropTypes.string,

};
CourseCard.defaultProps = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 4,
}
