import React, { useCallback, useState, useEffect } from 'react';
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";

import {makeStyles, useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

import PageNotExistScreen from '../pageNotFoundScreen';
import RatingDistributionCard from "../shared/ratingDistributionCard";
import RatingList from "../course/ratingList";
import {PublicUser} from "../../models/user";
import {listUserRating} from "../../actions/userAction";
import {NotFoundError} from "../../models/error";
import _ from "../../lodash-wrapper";

import { Link } from 'react-router-dom';
import {
    getTeacherRatingUrl
} from '../../utils';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '0px 10px 0px 10px',
        backgroundColor: '#f9f9f9',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 10px 0px 10px',
        },
    },
    wide: {
        marginTop: '-70px',
        paddingLeft: 40   
    },
    narrow: {
        marginTop: '-70px',        
    },
    subtitle: {
        fontWeight: 600,
        fontSize: '1.1em',
        letterSpacing: '2px',
        color: '#333',
        padding: '10px 0 0 0',
        margin: '10px 0 10px 0',
    },
    teacherRatingPageLink: {
        color: '#6293C3',
        textDecoration: 'none',
    },
    moreLink: {
        color: '#6293C3',
        cursor: 'pointer',
        textDecoration: 'underline',
        marginTop: '10px',
        display: 'block',
    },
    noFeedback: {
        textAlign: 'center',
        width: '100%',
        fontStyle: 'italic',
        color: '#666',
    },

}));


const RatingsPaper = ({ teacher, displayMode = "full" }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const desktopVersion = useMediaQuery(theme.breakpoints.up('md'));

    const dispatch = useDispatch();

    const [ratings, setRatings] = useState([]);
    const [nextPage, setNextPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [randomIndex, setRandomIndex] = useState(null);
    
    const getMoreRatings = useCallback(async () => {
        if (isLoading || !hasMore) return;
        setIsLoading(true);
        try {
            const paginatedResult = await dispatch(listUserRating(teacher.id, nextPage));
            setRatings((prevRatings) => [...prevRatings, ...paginatedResult.results]);
            setNextPage(paginatedResult.next ? nextPage + 1 : null);
            setHasMore(!!paginatedResult.next);
            setError(null);              
        } catch (e) {
            if (e instanceof NotFoundError && ratings.length === 0) {
                setError(e);
            } else {
                console.error("Error loading ratings:", e);
                setHasMore(false);
            }
        } finally {
            setIsLoading(false);
        }
    }, [teacher.id, dispatch, nextPage, hasMore, isLoading]);

    useEffect(() => {
        if (ratings.length === 0 && !error && hasMore) {
            getMoreRatings();
        }
    }, [getMoreRatings, ratings.length, error, hasMore]);

    if (error && error instanceof NotFoundError && ratings.length === 0) {
        return <PageNotExistScreen />;
    }

    // Set a random index on initial load if in simple mode
    useEffect(() => {
        if (displayMode === "simple" && ratings.length > 0) {
            setRandomIndex(Math.floor(Math.random() * ratings.length));
        }
    }, [displayMode, ratings.length]); 

    //const displayedRatings = displayMode === "simple" ? ratings.slice(0, 1) : ratings;
    const displayedRatings = displayMode === "simple" && randomIndex !== null ? 
        [ratings[randomIndex]] : ratings;

    return (
        <Paper elevation={0} className={classes.paper} square={true}>           
            {error && !(error instanceof NotFoundError) && <Alert severity="error">{error.message}</Alert>}
            
            {displayMode === "simple" ? (
                displayedRatings.length > 0 && (
                <div>
                    <Typography className={classes.subtitle}>學生評價                     
                        <Link to={getTeacherRatingUrl(teacher.id)} className={classes.teacherRatingPageLink}>
                            {" 瀏覽更多"}
                        </Link> 
                    </Typography>
                    <div>                    
                    {displayedRatings.map((rating) => (
                        <div key={rating.id}>
                            <RatingList
                                ratings={[rating]}
                                showGroupLink={true}
                            />
                        </div>
                    ))}
                    </div>
                </div>                                                           
                )
            ) : (
                <Grid container spacing={2} direction={desktopVersion ? 'row' : 'column-reverse'} className={desktopVersion ? classes.wide : classes.narrow}>
                    <Grid item md={8} xs={12}>
                        <Typography className={classes.subtitle}>學生評價</Typography>
                        {ratings.length > 0 ? (
                            <RatingList
                                ratings={ratings}
                                loadMore={getMoreRatings}
                                hasMore={hasMore}
                                resultExhaustedMessage={`以上為對於導師 ${teacher.displayName} 的所有評論`}
                                showGroupLink={true}
                            />
                        ) : (
                            <div>
                                <Typography className={classes.noFeedback}>暫時未有評價</Typography>
                            </div>
                        )}
                    </Grid>
                    <Grid item md={4} xs={12} >                       
                        <Grid>                                
                            <Typography className={classes.subtitle}>
                                評分統計
                            </Typography>
                            <RatingDistributionCard
                                ratingDistribution={teacher.ratingDistribution}
                                ratingAverage={teacher.ratingAverage}
                            />
                        </Grid>                        
                    </Grid>                        
                </Grid>
            )}
        </Paper>
    );

};

RatingsPaper.propTypes = {
  teacher: PropTypes.instanceOf(PublicUser).isRequired,
  displayMode: PropTypes.oneOf(["simple", "full"]),
};

export default RatingsPaper;
